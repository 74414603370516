.chat-window-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  cursor: move;
}

.chat-window-header button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.chat-window-title {
  flex-grow: 1;
}

.chat-window {
  position: absolute;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 220px;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
}

.chat-window.minimized {
  height: 30px !important;
  width: 220px !important;
  resize: none;
  min-height: 30px !important;
}

.chat-window.minimized .chat-window-content {
  display: none;
}

.chat-window-content {
  padding: 10px;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  will-change: transform;
  transform: translateZ(0);
}

.chat-window.crumpled {
  transform: scale(0.8) rotate(5deg);
  opacity: 0.7;
}

.chat-window-minimize,
.chat-window-crumple,
.chat-window-close {
  margin-left: auto;
}

.resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-draggable {
  position: absolute !important;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.window-control.delete {
  margin-left: 8px;
  font-size: 14px;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
}

.window-control.delete:hover {
  color: #ff4444;
}

.crumpled-note:hover {
  transform: rotate(15deg) scale(1.1) !important;
}

.crumpled-note:active {
  transform: rotate(15deg) scale(0.95) !important;
}
