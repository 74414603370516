.forum-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.forum-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.new-topic-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.new-topic-button:hover {
  background-color: #45a049;
}

.new-topic-form {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.new-topic-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.new-topic-form input,
.new-topic-form textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.new-topic-form textarea {
  min-height: 150px;
  resize: vertical;
}

.form-buttons {
  display: flex;
  gap: 10px;
}

.form-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-buttons button[type="submit"] {
  background-color: #4CAF50;
  color: white;
}

.cancel-button {
  background-color: #dc3545;
  color: white;
}

.topics-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.topic-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.topic-card h2 {
  margin: 0 0 10px 0;
  color: #333;
}

.topic-content {
  color: #666;
  margin-bottom: 15px;
}

.topic-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #777;
  font-size: 0.9em;
}

.topic-meta {
  display: flex;
  align-items: center;
  gap: 5px;
}

.topic-author {
  font-style: italic;
}

.loading {
  text-align: center;
  padding: 20px;
  color: #666;
} 