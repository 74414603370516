:root {
  --primary-color: #333333;
  --secondary-color: #666666;
  --accent-color: #999999;
  --background-color: #F7F7F7;
  --text-color: #31302B;
  --light-gray: #E0E0E0;
  --dark-gray: #4D4D4D;
  --alt-text-color: #E69AC2;
}


body {
  font-family: 'Lucida Console', monospace;
  font-size: 18px;
  color: #111;
  background-color: white;
  margin: 0;
  padding: 0;
}

.home-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 50px;
  box-sizing: border-box;
  position: relative;
  min-height: 100vh;
}

.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-height: 100dvh;
  text-align: center;
}

.svg-container {
  width: 100%;
  max-width: 480px;
  display: inline-block;
}

svg {
  width: 100%;
  height: auto;
}

.links-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 0;
}

.links-stack {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.links-stack a {
  text-decoration: none;
}

.links-container a {
  color: inherit;
  text-decoration: none;
  margin: 0 10px;
  margin-bottom: 20px;
  padding: 5px 10px; /* Added padding around links */
}

.separator {
  letter-spacing: -9px;
  margin: 0 5px; /* Added margin around separators */
}

hr {
  border: none;
  height: 2px;
  background-color: lightgrey;
  width: 100%;
  margin: 30px auto;
}

h2 {
  text-align: center;
}

.cta-button {
  background-color: transparent;
  color: var(--text-color);
  border: 1px solid var(--accent-color);
  padding: 0.4rem 0.6rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  display: inline-block;
  margin: 20px 0;
}

.cta-button:hover {
  background-color: var(--accent-color);
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(153, 153, 153, 0.2);
}

.faqtable dt {
  margin-bottom: 0.75em;
  font-style: italic;
}

.faqtable dd {
  margin-left: 0;
  margin-bottom: 2em;
}

.home-footer {
  text-align: center;
  padding: 1.5rem;
  font-size: 0.9rem;
  margin-top: 30px;
}

/* Responsive design */
@media (max-width: 768px) {
  .home-container {
    padding: 0 20px;
  }

  .links-container {
    font-size: 18px;
  }

  .cta-button {
    font-size: 1rem;
    padding: 0.8rem 1.6rem;
  }
}

.notes-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
}

.notes-overlay.minimized {
  top: calc(100% - 40px);
  background-color: rgba(255, 255, 255, 0.98);
}

.notes-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 8px;
  z-index: 1001;
}

.control-button {
  width: 30px;
  height: 30px;
  border: 1px solid var(--light-gray);
  background-color: transparent;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: 'Lucida Console', monospace;
  font-size: 20px;
  color: var(--dark-gray);
  transition: all 0.2s ease;
  border-radius: 0px;
  border-color: #ff1493;
}

.control-button:hover {
  background-color: var(--light-gray);
}

.control-button.close:hover {
  background-color: #ff1493;
  color: white;
  border-color: #ff1493;
}

.control-button.minimize:hover {
  background-color: var(--accent-color);
  color: white;
  border-color: var(--accent-color);
}

/* Add these styles */
.link-wrapper {
  position: relative;
  display: block;
  padding-left: 10px;
  transition: transform 0.2s ease;
}

.link-wrapper:hover {
  transform: scale(1.05);
}

.pink-arrow {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  font-size: 40px;
  color: #FF1493;
}

.link-wrapper:hover .pink-arrow {
  opacity: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
