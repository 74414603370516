.settings-container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.08);
  padding: 10px;
  width: 90%;
  max-width: 450px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  min-width: 300px;
  min-height: 90vh;
  overflow-y: auto;
  scrollbar-width: none; 
  -ms-overflow-style: none;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
  /* padding: 10px; */
}

.settings-header h2 {
  font-size: 20px;
  font-weight: 500;
  color: #1d1d1f;
}

.close-button {
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #86868b;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.close-button:hover {
  background-color:black
}

.settings-tabs-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.settings-tabs {
  display: flex;
  gap: 24px;
  border-bottom: 1px solid #f5f5f7;
  padding-bottom: 4px;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scroll-behavior: smooth;
}

.settings-tabs::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.tab-scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #e4e4e4;
  color: #86868b;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  transition: all 0.2s ease;
  padding: 0;
  line-height: 1;
}

.tab-scroll-button:hover {
  background: #f5f5f7;
  color: #1d1d1f;
}

.tab-scroll-button.left {
  left: 0;
  box-shadow: 4px 0 6px rgba(0, 0, 0, 0.05);
}

.tab-scroll-button.right {
  right: 0;
  box-shadow: -4px 0 6px rgba(0, 0, 0, 0.05);
}

.settings-tabs button {
  padding: 8px 4px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 13px;
  color: #86868b;
  position: relative;
  transition: color 0.2s;
  white-space: nowrap;
}

.settings-tabs button.active {
  color: #1d1d1f;
  font-weight: 500;
}

.settings-tabs button.active::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #1d1d1f;
}

.settings-section {
  padding: 16px 0;
}

.settings-section h3 {
  font-size: 17px;
  font-weight: 500;
  color: #1d1d1f;
  margin-bottom: 8px;
}

.settings-section p {
  font-size: 13px;
  color: #86868b;
  margin-bottom: 24px;
}

/* Connection Items */
.connection-item {
  display: flex;
  align-items: left;
  padding: 16px;
  border: 1px solid #f5f5f7;
  border-radius: 8px;
  transition: all 0.2s ease;
  margin-bottom: 8px;
}

.connection-item:hover {
  background-color: #f5f5f7;
}

.connection-item svg {
  font-size: 24px;
  color: #86868b;
}

.connection-item span {
  font-size: 14px;
  margin-left: 12px;
  color: #1d1d1f;
}

/* Switch/Slider styles */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 28px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e4e4e4;
  transition: .2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

input:checked + .slider {
  background-color: #FFC0CB;
}

/* Form styles */
.form-group label {
  font-size: 13px;
  color: #86868b;
  margin-bottom: 4px;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 8px 12px;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #FFC0CB;
  outline: none;
}

/* Permissions styles */
.platform-permissions {
  margin-bottom: 24px;
  border: 1px solid #f5f5f7;
  border-radius: 12px;
  padding: 16px;
}

.platform-header h4 {
  font-size: 15px;
  font-weight: 500;
}

.connection-required {
  font-size: 12px;
}

.permission-item {
  padding: 12px;
  border: 1px solid #f5f5f7;
  border-radius: 6px;
}

.permission-label span {
  font-size: 13px;
  color: #1d1d1f;
}

.permission-risk-level {
  font-size: 11px;
  padding: 3px 8px;
  border-radius: 4px;
}

/* Payment section */
.payment-card {
  border: 1px solid #f5f5f7;
  border-radius: 12px;
  padding: 20px;
  transition: transform 0.2s;
}

.payment-card:hover {
  transform: translateY(-2px);
}

.payment-card h4 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
}

.payment-card p {
  font-size: 13px;
  color: #86868b;
}

.payment-button {
  background-color: #FFC0CB;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.payment-button:hover {
  background-color: #FFC0CB;
}

/* Stripe Payment Styles */
.stripe-payment-form {
  max-width: 500px;
  margin: 20px auto;
}

.card-element-container {
  padding: 12px;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  background: white;
  margin-bottom: 16px;
}

.payment-error {
  color: #dc3545;
  font-size: 13px;
  margin: 8px 0;
}

.payment-submit-button {
  width: 100%;
  padding: 12px;
  background: #FFC0CB;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.payment-submit-button:disabled {
  background: #86868b;
  cursor: not-allowed;
}

.subscription-tiers {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
}

.subscription-card {
  border: 1px solid #f5f5f7;
  border-radius: 12px;
  padding: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.subscription-card.selected {
  border-color: #FFC0CB;
  box-shadow: 0 2px 12px rgba(0, 122, 255, 0.1);
}

.subscription-card:hover {
  transform: translateY(-2px);
}

.subscription-card h4 {
  font-size: 18px;
  margin-bottom: 8px;
}

.subscription-card .price {
  font-size: 24px;
  font-weight: 500;
  color: #007aff;
  margin-bottom: 16px;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.features-list li {
  font-size: 14px;
  color: #86868b;
  margin-bottom: 8px;
  padding-left: 20px;
  position: relative;
}

.features-list li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #FFC0CB;
}

.select-plan-button {
  width: 100%;
  padding: 10px;
  background: #FFC0CB;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}

.payment-form-container {
  border-top: 1px solid #f5f5f7;
  padding-top: 24px;
  margin-top: 24px;
}

/* Balance Display */
.balance-display {
  background: linear-gradient(135deg, #FFC0CB, #FFC0CB);
  color: white;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 32px;
}

.balance-display h3 {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 8px;
}

.balance-amount {
  font-size: 36px;
  font-weight: 500;
}

/* Top-up Section */
.preset-amounts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
}

.amount-card {
  border: 1px solid #f5f5f7;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
}

.amount-card:hover {
  transform: translateY(-2px);
  border-color: #FFC0CB;
}

.amount-card.selected {
  border-color: #FFC0CB;
  background-color: #f0f7ff;
}

.amount-value {
  font-size: 24px;
  font-weight: 500;
  color: #1d1d1f;
}

.amount-bonus {
  font-size: 13px;
  color: #FFC0CB;
  margin: 8px 0;
}

.amount-total {
  font-size: 12px;
  color: #86868b;
}

.custom-amount {
  margin-bottom: 24px;
}

.custom-amount label {
  display: block;
  margin-bottom: 8px;
  font-size: 13px;
  color: #86868b;
}

.custom-amount-input {
  display: flex;
  align-items: center;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  padding: 8px 12px;
  max-width: 200px;
}

.custom-amount-input span {
  color: #86868b;
  margin-right: 8px;
}

.custom-amount-input input {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
}

.custom-amount-bonus {
  font-size: 13px;
  color: #FFC0CB;
  margin-top: 8px;
}

.proceed-payment-button {
  background-color: #FFC0CB;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
  max-width: 300px;
}

.proceed-payment-button:disabled {
  background-color: #86868b;
  cursor: not-allowed;
}

.amount-summary {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
  color: #1d1d1f;
}

/* Info Tab Styles */
.info-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-block {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  overflow: hidden;
}

.info-block h3 {
  font-size: 15px;
  font-weight: 500;
  color: #1d1d1f;
  padding: 16px;
  margin: 0;
  border-bottom: 1px solid #f0f0f0;
}

.info-block h4 {
  font-size: 14px;
  font-weight: 500;
  color: #1d1d1f;
  margin: 16px 0 8px 0;
}

.info-content {
  padding: 16px;
}

.info-content.scrollable {
  max-height: 200px;
  overflow-y: auto;
}

.info-content p {
  font-size: 13px;
  color: #86868b;
  line-height: 1.5;
  margin: 8px 0;
}

.info-content ul {
  list-style: none;
  padding-left: 16px;
  margin: 8px 0;
}

.info-content ul li {
  font-size: 13px;
  color: #86868b;
  margin: 4px 0;
  position: relative;
}

.info-content ul li:before {
  content: "•";
  color: #FFC0CB;
  position: absolute;
  left: -12px;
}

.version-info,
.build-info,
.contact-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.version-info:last-child,
.build-info:last-child,
.contact-item:last-child {
  border-bottom: none;
}

.contact-item span {
  font-size: 13px;
  color: #86868b;
}

.contact-item a {
  font-size: 13px;
  color: #FFC0CB;
  text-decoration: none;
  transition: color 0.2s;
}

.contact-item a:hover {
  color: #ff9aa2;
}

/* Scrollbar styling for the scrollable sections */
.info-content.scrollable::-webkit-scrollbar {
  width: 6px;
}

.info-content.scrollable::-webkit-scrollbar-track {
  background: #f5f5f7;
  border-radius: 3px;
}

.info-content.scrollable::-webkit-scrollbar-thumb {
  background: #d1d1d6;
  border-radius: 3px;
}

.info-content.scrollable::-webkit-scrollbar-thumb:hover {
  background: #c7c7cc;
}

/* Personal Info Tab Styles */
.personal-info-section {
  padding: 20px;
}

.personal-info-grid {
  display: grid;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.info-section {
  background: var(--surface-2);
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-section h3 {
  color: var(--text-1);
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.form-row {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.form-row.three-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-group label {
  color: var(--text-2);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background: var(--surface-1);
  color: var(--text-1);
  font-size: 0.95rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px var(--primary-color-transparent);
  outline: none;
}

.input-with-unit {
  position: relative;
  display: flex;
  align-items: center;
}

.input-with-unit input {
  padding-right: 3rem;
  width: 100%;
}

.input-with-unit .unit {
  position: absolute;
  right: 1rem;
  color: var(--text-2);
  font-size: 0.9rem;
}

.character-count {
  color: var(--text-3);
  font-size: 0.8rem;
  text-align: right;
  margin-top: 0.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-row.three-columns {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .form-row {
    flex-direction: column;
    gap: 1rem;
  }
}