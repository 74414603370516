.notes-list-mobile {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: white;
}

.notes-list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  /* border-bottom: 1px solid #eee; */
}

.notes-list-header h1 {
  color: #FF1493;
  font-size: 1.2rem;
  font-weight: normal;
  margin: 0;
  letter-spacing: 2px;
}

.new-note-button,
.select-mode-button,
.delete-selected-button,
.cancel-selection-button {
  padding: 0.5rem 1rem;
  background: none;
  border: none;
  font-size: 1rem;
  color: #FF1493;
  cursor: pointer;
  font-family: 'Lucida Console', monospace;
  height: 32px;
  display: flex;
  align-items: center;
}

.new-note-button:hover,
.select-mode-button:hover,
.delete-selected-button:hover,
.cancel-selection-button:hover {
  text-decoration: underline;
}

.select-mode-button,
.cancel-selection-button {
  color: #666;
}

.delete-selected-button {
  color: #FF1493;
}

.delete-selected-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  text-decoration: none;
}

.search-container {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.search-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 0;
  font-size: 0.9rem;
  outline: none;
  font-family: 'Lucida Console', monospace;
}

.search-input:focus {
  border-color: #FF1493;
  box-shadow: 0 0 0 2px rgba(255, 20, 147, 0.1);
}

.notes-list-content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.note-item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.note-item:hover {
  background: #f9f9f9;
}

.note-item:hover::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background: #FF1493;
}

.note-preview {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.note-meta {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #666;
}

.note-timestamp {
  color: #999;
}

.note-word-count {
  background: yellow;
  padding: 0 4px;
  display: inline-block;
}

.no-notes {
  text-align: center;
  color: #FF1493;
  padding: 2rem;
  font-style: italic;
}

.notes-list-actions {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.note-item.selected {
  background-color: rgba(255, 20, 147, 0.1);
}

.note-checkbox {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.note-checkbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .notes-list-header h1 {
    font-size: 1.2rem;
  }
  
  .note-preview {
    font-size: 0.85rem;
  }
} 