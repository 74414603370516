.similar-text-popup {
  position: fixed;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  width: 500px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  right: 0;
    
}

.similar-text-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.similar-text-content {
  margin-bottom: 10px;
  font-size: 10px;
  word-wrap: break-word;
}

.similar-text-buttons {
  display: flex;
  justify-content: flex-end;
}

.similar-text-buttons button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  color: #666;
  font-size: 12px;
}

.similar-text-buttons button:hover {
  color: #333;
}
