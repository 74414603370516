.auto-scroller {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.scroller-controls {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}


.scroll-history {
  margin-top: 20px;
}

.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.clear-history-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background: #e0e0e0;
  cursor: pointer;
  transition: background 0.3s;
}

.clear-history-button:hover {
  background: #bdbdbd;
}

.history-list {
  max-height: 300px;
  overflow-y: auto;
}

.history-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.history-item .platform {
  text-transform: uppercase;
  font-size: 0.8em;
  padding: 2px 6px;
  border-radius: 3px;
  background: #e0e0e0;
}

.video-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.video-item {
  width: 100%;
  max-width: 800px;
  background: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.video-item.youtube {
  aspect-ratio: 16/9;
  padding: 20px;
}

.video-item.tiktok {
  aspect-ratio: 9/16;
  padding: 10px;
  max-width: 400px;
}

.video-item iframe {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

/* For TikTok's vertical format */
.video-item.tiktok iframe {
  max-height: 80vh;
}

/* Smooth scrolling for the entire page */
html {
  scroll-behavior: smooth;
}

/* Hide scrollbar but keep functionality */
body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}