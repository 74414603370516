.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chatbot-toggle {
  width: 60px;
  height: 60px;
  /* float: right; */
  border-radius: 30px;
  background-color: #df72ac;
  border: none;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.chatbot-toggle:hover {
  transform: scale(1.1);
}

.chatbot-window {
  width: 350px;
  height: 500px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.chatbot-header {
  padding: 10px;
  float: right;
  background-color: #df72ac;
  color: white;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.chatbot-content {
  flex-grow: 1;
  overflow-y: auto;
  /* padding: 15px; */
}

.chatbot-input {
  display: flex;
  padding: 15px;
  border-top: 1px solid #eee;
}

.chatbot-input input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-right: 10px;
}

.chatbot-input button {
  background-color: #ddd;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.message {
  margin-bottom: 10px;
  max-width: 80%;
  clear: both;
}

.user-message {
  float: right;
}

.ai-message {
  float: left;
}


.loading-message {
  background: none;
}

.loading-dots {
  display: flex;
  align-items: center;
  gap: 5px;
}

.loading-dots span {
  width: 8px;
  height: 8px;
  background-color: #df72ac;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
}

.loading-dots span:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-dots span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% { 
    transform: scale(0);
  } 
  40% { 
    transform: scale(1.0);
  }
} 