.embedding-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:transparent;
  z-index: 100;
  transition: all 0.3s ease;
}

.embedding-background.minimized {
  top: calc(100% - 40px);
}

.embedding-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 8px;
  z-index: 101;
}

.embedding-visualization-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.embedding-visualization-svg {
  width: 100%;
  height: 100%;
}

.tooltip {
  position: absolute;
  text-align: center;
  padding: 8px;
  font: 12px 'Lucida Console', monospace;
  background: white;
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  pointer-events: none;
  z-index: 502;
}
