.notes-list {
  width: 250px;
  background: white;
  border-right: 1px solid #ddd;
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
}

.notes-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem 0;
}

.delete-selected-button {
  font-family: 'Lucida Console', monospace;
  background: transparent;
  color: #FF1493;
  border: none;
  padding: 4px 8px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-selected-button:hover {
  transform: scale(1.05);
}

.notes-items {
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
}

.note-item {
  background: transparent;
  padding: 0.1rem;
  border: none;
  cursor: move;
  transition: all 0.2s ease;
  font-family: 'Lucida Console', monospace;
}

.note-item.selected .note-preview {
  background: yellow;
  transform: rotate(-1deg);
}

.note-item:hover {
  transform: scale(1.02);
}

.note-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.2rem;
}

.note-preview {
  flex: 1;
  font-size: 0.9rem;
  color: #FF1493;
  padding: 2px 4px;
  transition: all 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.note-item:hover .note-preview {
  /* background: yellow; */
  /* transform: rotate(-1deg); */
}

.select-button {
  width: 20px;
  height: 20px;
  border: 2px solid #FF1493;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FF1493;
  font-size: 12px;
  padding: 0;
  transition: all 0.2s ease;
}

.select-button:hover {
  background: yellow;
  /* transform: rotate(-2deg); */
}

.select-button.selected {
  background: yellow;
  border-color: #FF1493;
  color: #FF1493;
  /* transform: rotate(-2deg); */
}

/* Media Queries for Mobile */
@media screen and (max-width: 768px) {
  .notes-list {
    width: 150px;
    padding: 0.5rem;
    scale: 0.9;
  }

  .delete-selected-button {
    font-size: 0.7rem;
  }

  .note-preview {
    font-size: 0.8rem;
    /* less max characters */
    /* max-width: 100px; */
  }

  .select-button {
    width: 16px;
    height: 16px;
    font-size: 10px;
  }
}

@media screen and (max-width: 480px) {
  .notes-list {
    width: 140px;
  }

  .note-preview {
    font-size: 0.75rem;
  }
}

/* Add these styles for the new note button */
.new-note-button {
  font-family: 'Lucida Console', monospace;
  background: transparent;
  color: #FF1493;
  border: 2px solid #FF1493;
  border-radius: 4px;
  width: 28px;
  height: 28px;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  /* transition: all 0.1s ease; */
}

.new-note-button:hover {
  background: yellow;
  /* transform: rotate(-2deg); */
} 