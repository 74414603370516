.visualization-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.notes-playground {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.notes-interface-container {
  scale: 0.9;
  position: absolute;
  top: 0%;
  left: 100px;
  bottom: 200px;
  width: 40%;
  min-width: 30%;
  background-color: rgba(255, 255, 255, 0.01);
  border-radius: 20px;
  /* box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3); */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 2;
  /* border: 3px solid #333; */

}



@media (max-width: 600px) {
  .notes-interface-container {
    scale: 0.9;
    width: 100%;
    left: 0;
    min-width: unset;
    top: -20px;
    bottom: 400px;
  }
}

@media (max-height: 700px) {
  .notes-interface-container {
    top: 5px;
    bottom: 40px;
  }
}

.notes-interface-container.expanded {
  width: 90%;
  left: 5%; /* Center horizontally by setting left margin to 5% */
  right: 5%;
  max-width: 95vw; /* Prevent overflow on very small screens */
  min-width: 300px; /* Minimum width for usability */
}





.notes-interface {
  position: relative;
  display: flex;
  height: 100%;
}

/* when minimized */
.notes-interface.minimized {
  max-height: 400px;
}


.conversation-history {
  max-width: 150px;
  min-width: 150px;
  transition: max-width 0.3s ease-in-out, min-width 0.3s ease-in-out;
  background-color: #f0f0f0;
  border-radius: 20px 0px 0px 20px;
  overflow-y: hidden;
  /* box-shadow: 2px 0 6px rgba(0, 0, 0, 0.15); */
  /* border-right: 2px solid #ddd; */
}

@media (max-width: 768px) {
  .conversation-history {
    width: 10%;
    height: 100%;
    min-width: 100px;
  }

  .conversation-history:not(.hidden) {
    transform: translateX(0);
  }


  .conversation-history .delete-conversation {
    padding: 8px;
    font-size: 20px;
  }
}


.conversation-history.hidden {
  max-width: 0;
  min-width: 0;
  overflow: hidden;
}

.conversation-history ul {
  list-style-type: none;
  padding: 0;
}

.conversation-history li {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  cursor: pointer;
  padding: 8px 10px;
  margin: 4px;
  border-bottom: 2px solid #eee;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .conversation-history li {
    padding: 0px 10px;
    font-size: 10px;
    margin: 0px;
  }
}

.conversation-history li.active {
  background-color: #333333;
  color: white;
  /* font-weight: 500; */
}

.conversation-history li.active .delete-conversation {
  color: #ffffff;
}

.conversation-history li:hover {
  background-color: #ff69b4;
  color: white;
  /* border-radius: 2px; */
}

.conversation-history li:hover .delete-conversation {
  color: #ffffff;
}

.conversation-history li span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete-conversation {
  background-color: transparent;
  border: none;
  color: #999;
  font-size: 18px;
  cursor: pointer;
  padding: 0 5px;
  margin-left: 5px;
  transition: color 0.3s ease;
}

.delete-conversation:hover {
  color: #242121;
}

.conversation-history li.active {
  background-color: #c4c4c4;  /* Darker background color */
  font-weight: 500;  /* Slightly bolder text */
  border-radius: 2px;  /* Rounded corners */
  /* margin: 0 4px;  Add some margin for better visual separation */
  /* border-bottom: 1px solid #b0b0b0;  Slightly darker border */
}

.conversation-history li.active:hover {
  background-color: #b8b8b8;  /* Even darker on hover */
}

.chat-area {
  padding-top: 20px;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  transition: width 0.3s ease-in-out;
  -ms-overflow-style: none;  /* Internet Explorer and Edge */
  scrollbar-width: none;  /* Firefox */
  width: 100%;
}

/* Add this new rule to hide scrollbar in WebKit browsers (Chrome, Safari) */
.chat-area::-webkit-scrollbar {
  display: none;
}

.chat-area.expanded {
  width: 100%; /* Take up full width when expanded */
}

.input-area {
  display: flex;
  flex-direction: column;
  padding: 4px;
  position: relative;
}

.input-field-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.input-field {
  flex-grow: 1;
  margin-right: 0px;
  /* background-color: whiteba */
  border: 1px solid #ff1493; 
  border-radius: 0px;
  padding: 12px 45px 12px 20px;
  font-size: 14px;
  width: 100%;
  transition: all 0.3s ease;
}

.input-field:focus {
  outline: none;
  border-color: #333;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.send-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.send-button i {
  color: #555;
  font-size: 18px;
}

.send-button:hover i {
  color: #333;
}

.interface-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  background-color: transparent;
}

.interface-buttons button {
  background: none;
  border: none;
  color: #555;
  font-size: 18px;
  cursor: pointer;
  padding: 5px 10px;
  margin: 0;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  width: 40px; /* Fixed width */
  height: 40px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.interface-buttons button:hover {
  opacity: 1;
}

.message {
  max-width: 100%;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace;
}

.user-message {
  align-self: flex-end;
  background-color: #333;
  color: #fff;
  max-width: 80%;
  margin-right: 10px;
  border-radius: 25px 25px 5px 25px;
  padding: 12px 16px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}

.ai-message {
  align-self: flex-start;
  margin-left: 0px;
  background-color: transparent;
  width: 90%;
  border-radius: 25px 25px 25px 5px;
  padding: 12px 16px;
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1); */
  /* border: 2px solid #e0e0e0; */
}

.ai-message .message-content {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.ai-message pre {
  background-color: #080000;
  max-width: 100%;
  padding: 10px;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ai-message code {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1em;
}

.message-content {
  word-wrap: break-word;
}

.chat-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 5px;
  font-family: Arial, sans-serif;
  line-height: 1.4;
  color: #333;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.chat-content::-webkit-scrollbar {
  display: none;
}

.chat-content p {
  margin: 2px 2px 2px 2px;
}

.message-content h1,
.message-content h2,
.message-content h3,
.message-content h4,
.message-content h5,
.message-content h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.message-content h1 { font-size: 2em; }
.message-content h2 { font-size: 1.5em; }
.message-content h3 { font-size: 1.17em; }
.message-content h4 { font-size: 1em; }
.message-content h5 { font-size: 0.83em; }
.message-content h6 { font-size: 0.67em; }

.message-content p {
  /* margin-bottom: 1em; */
}

.message-content ul,
.message-content ol {
  margin-left: 0em;
  margin-bottom: 1em;
}

.message-content li {
  margin-bottom: 0.5em;
}

.message-content code {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.9em;
}

.message-content pre {
  background-color: #333;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  overflow-x: auto;
  margin-left: 0em;
  color: white
}

.message-content blockquote {
  border-left: 4px solid #ccc;
  padding-left: 1em;
  /* margin-left: -1em; */
  margin-bottom: 1em;
  font-style: italic;
}

.similar-texts-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0; /* Set to 0 to not affect layout */
  overflow: visible; /* Allow popups to be visible outside container */
}

.copy-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(228, 228, 228, 0.3); 
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 8px;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

pre {
  position: relative;
}

.reference {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}

.reference:hover {
  color: #0056b3;
}

.reference-popup {
  position: fixed;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  max-width: 500px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 1000;
  font-size: 10px;
  line-height: 1.4;
  pointer-events: none; /* Prevents the popup from interfering with hover */
}


.ai-message-buttons-container {
  padding: 0px;
  margin-left: 15px;

}

.ai-message-button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-right: 5px;
  padding: 5px 5px;
  opacity: 0.6;
}

.ai-message-button:hover {
  opacity: 1;
}

.loading-message {
  background: none;
  align-self: flex-start;
  margin-left: 10px;
  padding: 10px;
  width: auto;
  display: flex;
  align-items: center;
}

.loading-dots {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 15px;
  background-color: #ffffff;
  /* border-radius: 20px; */
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
}

.loading-dots span {
  width: 10px;
  height: 10px;
  background-color: #333333;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
  opacity: 0.7;
}

.loading-dots span:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-dots span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% { 
    transform: scale(0);
  } 
  40% { 
    transform: scale(1.0);
  }
}
