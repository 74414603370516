.scroll-notes {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  flex-direction: column;
  font-family: 'Lucida Console', monospace;
}

.notes-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.2rem;
  color: #FF1493;
  font-family: 'Lucida Console', monospace;
}

.scroll-notes * {
  font-family: 'Lucida Console', monospace;
} 