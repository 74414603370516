.node-details {
  position: relative;
  top: 50%;
  right: 20px;
  background-color: #f0f0ff;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
}

.node-details h3 {
  margin-top: 0;
}

.node-details ul {
  padding-left: 20px;
}

.node-details li {
  margin-bottom: 5px;
}

.node-details button {
  margin-top: 10px;
}

.graph-visualization {
  position: relative;
  width: 100%;
  height: 100%;
}

.graph-visualization svg {
  width: 100%;
  height: 100%;
}


.node-label, .link-label {
  pointer-events: none;
  font-family: Arial, sans-serif; 
  font-size: 3px;
}

.node-label {
  fill: #333;
  /* font-weight: bold; */
}

.link-label {
  fill: #252525;
  text-anchor: middle;
}

.delete-nodes-button {
  background-color: #ff4444;
  color: white;
}

.delete-nodes-button:hover {
  background-color: #cc0000;
}

.graph-controls {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 1000;
}

.control-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.find-stray-button {
  background-color: #4CAF50;
  color: white;
}

.find-stray-button:hover {
  background-color: #45a049;
}

.stats-panel {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
  padding: 15px;
  width: 400px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 20vh;
  overflow: hidden;
  transition: transform 0.3s ease-out;
}

.stats-panel.visible {
  transform: translateX(-50%) translateY(0);
}

.toggle-stats-button {
  position: fixed;
  bottom: 50%;
  right: 0px;
  transform: translateX(-50%) translateY(100%);
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-out;
  z-index: 1001;
  rotate: 90deg;
}

.stats-panel.visible + .toggle-stats-button {
  transform: translateX(-50%) translateY(0);
}

.toggle-stats-button::after {
  content: '▲';
  font-size: 16px;
}

.stats-panel.hidden + .toggle-stats-button::after {
  content: '▼';
}

.stats-panel h3 {
  margin: 0 0 15px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  font-size: 16px;
  color: #333;
}

.stats-panel h4 {
  margin: 10px 0 5px 0;
  font-size: 14px;
  color: #666;
}

.stats-content {
  margin-bottom: 15px;
  scroll-behavior: smooth;
  max-height: calc(80vh - 120px);
  overflow-y: auto;
}

.stats-content p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.relationship-types ul {
  margin: 5px 0;
  padding-left: 20px;
  font-size: 13px;
  color: #666;
  padding-bottom: 10px;
}

.stats-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stats-buttons button {
  width: 100%;
}

.control-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.find-stray-button {
  background-color: #4CAF50;
  color: white;
}

.find-stray-button:hover {
  background-color: #45a049;
}

.selected-nodes ul {
  margin: 5px 0;
  padding-left: 20px;
  font-size: 13px;
  color: #666;
  max-height: 150px;
  overflow-y: auto;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.selected-nodes li {
  margin-bottom: 3px;
}

.stats-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.stats-header h3 {
  margin: 0;
  padding: 0;
  border: none;
}

.minimize-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;
}

.minimize-button:hover {
  color: #333;
}

.stats-collapsible {
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.stats-panel.minimized .stats-collapsible {
  max-height: 0;
}

.stats-panel.minimized {
  max-height: 60px;
  overflow: hidden;
}

@media (max-width: 600px) {
  .stats-panel {
    scale: 0.9;
  }
}

.random-walk-controls {
  padding: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  margin-top: 10px;
}

.random-walk-controls button {
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.random-walk-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.random-walk-controls input[type="range"] {
  width: 100px;
  margin: 0 10px;
}

.random-walk-section {
  border-top: 1px solid #eee;
  margin-top: 10px;
  padding-top: 10px;
}

.clickable-item {
  cursor: pointer;
  padding: 2px 4px;
  transition: background-color 0.2s;
}

.clickable-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.details-panel {
  margin-top: 1rem;
  padding: 0.8rem;
  background-color: #f5f5f5;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.details-panel h4 {
  margin: 0 0 0.8rem 0;
  color: #333;
  font-size: 0.9rem;
  font-weight: 600;
}

.details-content {
  max-height: 200px;
  overflow-y: auto;
}

.detail-item {
  display: flex;
  padding: 4px 0;
  font-size: 0.85rem;
  border-bottom: 1px solid #eee;
}

.detail-label {
  flex: 0 0 auto;
  min-width: 80px;
  color: #666;
  font-weight: 500;
}

.detail-value {
  flex: 1;
  color: #333;
  margin-left: 8px;
  word-break: break-word;
}

.relationship-item {
  margin-bottom: 12px;
  padding: 8px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #eee;
}

.relationship-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid #eee;
}

.from-node, .to-node {
  padding: 2px 6px;
  background-color: #e3f2fd;
  border-radius: 3px;
  font-size: 0.8rem;
  color: #1976d2;
}

.arrow {
  color: #666;
  font-size: 1.2rem;
}

.relationship-properties {
  padding-left: 8px;
}

.selected-nodes-controls {
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.95);
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: 'Lucida Console', monospace;
  max-width: 400px;
  text-align: center;
  font-size: 14px;
  color: #333;
}

.delete-nodes-btn {
  background-color: transparent;
  color: #FF1493;
  /* border: 1px solid #E0E0E0; */
  padding: 8px 16px;
  /* border-radius: 4px; */
  cursor: pointer;
  font-size: 14px;
  font-family: 'Lucida Console', monospace;
  transition: all 0.2s ease;
}

.delete-nodes-btn:hover {
  background-color: #FF1493;
  color: white;
  /* border-color: #FF1493; */
  transform: translateY(-1px);
}

.description-panel {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.95);
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: 'Lucida Console', monospace;
  max-width: 400px;
  text-align: center;
  font-size: 14px;
  color: #333;
}

.bottom-controls {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 10px 20px;
  /* border: 1px solid #FF1493; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  z-index: 1000;
  font-family: 'Lucida Console', monospace;
}

.delete-nodes-button {
  background-color: transparent;
  color: #FF1493;
  border: 1px solid #FF1493;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Lucida Console', monospace;
  transition: all 0.2s ease;
}

.delete-nodes-button:hover {
  background: #FF1493;
  color: white;
  transform: translateY(-1px);
}

.stats-summary {
  color: #333;
  font-size: 12px;
  text-align: center;
  font-family: 'Lucida Console', monospace;
}

.node-descriptions {
  margin-top: 8px;
  max-width: 400px;
  text-align: left;
}

.node-description {
  font-family: 'Lucida Console', monospace;
  font-size: 11px;
  color: #666;
  padding: 8px 0;
  margin-top: 8px;
  border-top: 1px solid #eee;
  max-width: 400px;
  text-align: left;
}

.node-description:first-child {
  border-top: none;
}

/* Update bottom-controls to accommodate the new content */
.bottom-controls {
  max-width: 500px;
  background: white;
  padding: 12px 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  z-index: 1000;
  font-family: 'Lucida Console', monospace;
}
