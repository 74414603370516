.note-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: white;
  font-family: 'Lucida Console', monospace;
}

.note-view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  /* border-bottom: 1px solid #eee; */
  height: auto;
}

.back-button {
  padding: 0.5rem 1rem;
  background: none;
  border: none;
  font-size: 1rem;
  color: #FF1493;
  cursor: pointer;
  font-family: 'Lucida Console', monospace;
  height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  margin: 0;
}

.back-button:hover {
  background: yellow;
}

.note-actions {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.save-button,
.delete-button {
  padding: 0.5rem 1rem;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Lucida Console', monospace;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 64px;
  letter-spacing: 1px;
  margin: 0;
}

.save-button {
  color: #FF1493;
}

.save-button:hover {
  text-decoration: underline;
}

.delete-button {
  color: #666;
}

.delete-button:hover {
  text-decoration: underline;
}

.note-content {
  flex: 1;
  padding: 1rem;
  font-size: 1rem;
  border: none;
  resize: none;
  outline: none;
  font-family: 'Lucida Console', monospace;
  line-height: 1.5;
}

.note-content::placeholder {
  color: #999;
  font-style: italic;
}

/* Command hint styling */
.note-content[placeholder]::placeholder {
  background: yellow;
  padding: 0 4px;
  display: inline-block;
}

.save-button.has-changes {
  background-color: #ffd700;
  font-weight: bold;
} 