.new-chat-button {
  position: fixed;
  z-index: 1000;
  width: 50px;
  height: 50px;
  background-color: #4d504d;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: all 0.2s ease;
}

.new-chat-button:hover {
  background-color: #1a3e3d;
  color: white;
}


.new-chat-button.centered {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.new-chat-button.top-left {
  top: 20px;
  left: 20px;
}

.delete-node-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 8px 16px;
  background-color: rgba(36, 30, 30, 0.5);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.delete-node-btn:hover {
  background-color: #ff3333;
}
