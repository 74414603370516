/* .output, .input-line {
  font-family: var(--font-family);
  font-size: 0.7rem;
  color: var(--text-color);
  line-height: 0.6;
  width: 100%;
  max-width: 100%;
} */

.output {
  overflow-y: auto;
  white-space: pre-wrap;
  padding-right: 5px;
}

.input-line {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255);
  width: 100%;
  padding: 5px;
  /* border: 1px solid #ccc; */
  border-radius: 3px;
}

.prompt {
  margin-right: 5px;
  font-weight: bold;
  color: var(--primary-color);
}



.retrieved-docs, .answer {
  margin-top: 10px;
}

.retrieved-docs p, .answer p {
  margin: 5px 0;
}

.output::-webkit-scrollbar {
  display: none;
}

.chat-interface {
  position: relative;
  /* background-color: rgba(255, 255, 255, 0.8); */
  padding: 0;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-output {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content-textarea {
  flex-grow: 1;
  max-width: 99%;
  height: 100%;
  resize: none;
  border: none;
  outline: none;
  font-family: var(--font-family);
  font-size: 0.8rem;
  line-height: 1.5;
  padding: 10px;
  background-color: transparent;
  color: var(--text-color);
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}

.content-textarea:read-only {
  cursor: default;
}

/* Hide scrollbars for WebKit browsers */
.content-textarea::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbars for Firefox */
.content-textarea {
  scrollbar-width: none;
}

